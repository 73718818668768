@font-face {
    font-family: 'NanumBarunGothic';
    src: url("./css/fonts/NanumBarunGothicWebUltraLight.woff") format("woff");
}
@font-face {
    font-family: 'NanumBarunGothic';
    src: url("./css/fonts/NanumBarunGothicBoldSubset.woff") format("woff");
    font-weight: bold;
}
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
}

* {
    font-family: 'NanumBarunGothic';
    user-select: none;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

html,
body {
    background-color: #1e1e1e;
    width: 100%;
    height: 100%;

    margin: 0;
}

#root {
    margin: auto;
    width: 100%;
    max-width: 540px;
    gap: 1rem;
    display: flex;
	flex-direction: column;
	padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    height: calc(100% - 3rem);
}

button {
    font-size: 1.2rem;
    padding: 5px;
    color: #fff;
    background-color: #000;
    border: #000 solid 0px;
    border-radius: 10px;
    cursor: pointer;
    transition-duration: .25s;
    margin: auto;
    width: 50%;
}

button:hover {
    filter: blur(1px);
    transform: scale(0.9);
    transition-duration: .25s;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}