.searchResult {
    padding: 10px;
    background-color: #2e2e2e;
    border-radius: 15px;
    display: flex;
    gap: 1rem;
    transition-duration: .25s;
}
.searchResult:hover {
    cursor: pointer;
    transition-duration: .25s;
    transform: scale(0.9);
    filter: blur(1px);
}

.textContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.description {
    margin-top: 5px;
    font-size: .98rem;
    color: #888;
}

.results {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding-bottom: 10px;
}

.thumbnail {
    width:  calc(480px / 3);
    height: calc(360px / 3);
    border: #000 solid 0px;
    border-radius: 10px;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.navigator {
    padding: 10px;
    background-color: #2e2e2e;
    border-radius: 15px;
    display: flex;
    gap: 1rem;
}

.input {
    font-size: 1.2rem;
    padding: 5px;
    color: #fff;
    background-color: #000;
    border: #000 solid 0px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition-duration: .25s;
}
.input:focus {
    transition-duration: .25s;
    transform: scale(1.05);
    outline: 0;
}

.greyText {
    margin-top: auto;
    margin-left: auto;
    width: 100%;
    font-size: .87rem;
    color: #88888888;
    text-align: right;
}