.toCenter {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
}

.marginCenter {
    width: 100%;
    max-width: 280px;
    margin: auto;
    gap: .4rem;
    display: flex;
    flex-direction: column;
}

.message {
    margin: auto;
    font-size: 1.24rem;
    color: #fff;
}

.input {
    font-size: 1.2rem;
    padding: 5px;
    color: #fff;
    background-color: #000;
    border: #000 solid 0px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition-duration: .25s;
}
.input:focus {
    transition-duration: .25s;
    transform: scale(1.05);
    outline: 0;
}

.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .7rem;
}