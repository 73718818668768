.dialogBackground {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000088;
    z-index: 0;
    display: flex;
    opacity: 0;
}

.dialogBackground_hide {
    width: 0;
    height: 0;
}

.dialogBackground_show {
    opacity: 1;
}

.duration {
    transition-duration: .25s;
}

.textContainer {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 1.89rem;
    font-weight: bold;
    margin-bottom: 4px;
}

.dialog {
    display: flex;
    margin: auto;
    max-width: 380px;
    width: 100%;
    padding: 20px;
    padding-bottom: 10px;
    border: #000 solid 0px;
    border-radius: 15px;
    background-color: #2e2e2e;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    flex-direction: column;
}

.buttonContainer {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    float: right;
    gap: 1rem;
}