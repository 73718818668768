.infoCard {
    display: flex;
    border: #000 solid 0px;
    border-radius: 15px;
    width: 100%;

    padding: 6px;
    border: #000 solid 0px;
    border-radius: 15px;
    background-color: #2e2e2e;
}

.toCenter {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.marginCenter {
    width: 100%;
    max-width: 600px;
    margin: auto;
    gap: .4rem;
    display: flex;
    flex-direction: column;
}

.message {
    margin: auto;
    font-size: 1.24rem;
    color: #fff;
}

.thumbnailContainer {
    /*width:  calc(1280px / 3);*/
    height: calc( 720px / 3);
    /*max-width: 100%;*/
    max-height: 100%;
    display: flex;
    border: #000 solid 0px;
    border-radius: 10px;
    background-color: #000;
    padding: 7px;
}
.thumbnail {
    max-width: 100%;
    max-height: 100%;
    margin: auto;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    border: #000 solid 0px;
    border-radius: 10px;
}

.videoInfoContainer {
    gap: 16px;
    display: flex;
    flex-direction: column;
}

span {
    color: #fff;
    font-size: 1.24rem;
}

.title {
    font-size: 1.47rem;
    font-weight: bold;
    margin: auto;
}

.buttonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .7rem;
}

.back {
    margin-top: 10px;
    margin-bottom: 10px;
}

.description {
    cursor: pointer;
}